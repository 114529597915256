import React, { Component } from "react";
import {
  ImageBlock,
  ColorBlock,
  TextBlock,
  TableColumn,
  TableRow
} from "../components/blocks.component";
import { TextHeader } from "../components/text.component";

interface Desc {
  isPhone: boolean;
}
export const Description = (props: Desc) => (
  <div>
    <ColorBlock
      color={"rgba(212,211,212,1)"}
      vhHeight={!props.isPhone ? "70vh" : "100vh"}
      style={{ flex: 1, flexDirection: "column", display: "flex" }}
    >

      <TextBlock justifyContent={"flex-start"} height={"10%"} isPhone={!props.isPhone} >
        <TextHeader
          color={"rgba(25,51,89,1)"}
          marginLeft={"3vh"}
          size={"5vh"}
          fontFamily={"Avenir"}
          isPhone={props.isPhone}
          padding={"1vh"}
        >
          MINDBOOZT APP
        </TextHeader>
      </TextBlock>
      <div style={{ flex: 1, flexDirection: !props.isPhone ? "row" : "column", display: "flex", justifyContent: !props.isPhone ? 'center' : 'flex-start' }}>
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>
          <TableColumn justifyContent={"flex-start"} isPhone={props.isPhone} >
            <TableRow>

              <TextHeader
                color={"black"}
                marginLeft={"3vh"}
                size={"3.5vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"1vh"}
                isPhone={props.isPhone}
              >
                MEDITATIONS PROGRAM
            </TextHeader>
              <TextHeader
                color={"rgba(25,51,89,1)"}
                marginLeft={"3vh"}
                size={"2vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"25"}
                isPhone={props.isPhone}

              >
                som sänker dín stress och ori ,ger <br />
                bättre sömn, mer fokus mer <br /> välmáende och balans.
            </TextHeader>
            </TableRow>
          </TableColumn>

          <TableColumn justifyContent={"flex-start"} isPhone={props.isPhone}>

            <TableRow>

              <TextHeader
                color={"black"}
                marginLeft={"3vh"}
                size={"3.5vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"1vh"}
                isPhone={props.isPhone}
              >
                AVSLAPPNING
</TextHeader>
              <TextHeader
                color={"rgba(25,51,89,1)"}
                marginLeft={"3vh"}
                size={"2vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"25"}
                isPhone={props.isPhone}

              >
                Guidad avslppning, mindfullness <br /> hypnos och mental träning
  med <br /> och utan musik.
</TextHeader>

            </TableRow>
          </TableColumn>
        </div>
        <div style={{ flex: 1, flexDirection: "column", display: "flex" }}>

          <TableColumn justifyContent={"flex-start"} isPhone={props.isPhone}>
            <TableRow >

              <TextHeader
                color={"black"}
                marginLeft={"3vh"}
                size={"3.5vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"1vh"}
                isPhone={props.isPhone}
              >
                100 TALS LJUDFILER
</TextHeader>
              <TextHeader
                color={"rgba(25,51,89,1)"}
                marginLeft={"3vh"}
                size={"2vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"25"}
                isPhone={props.isPhone}

              >
                Program för nybörjare sáväl som van
</TextHeader>
            </TableRow>

          </TableColumn>
          <TableColumn justifyContent={"flex-start"} isPhone={props.isPhone}>
            <TableRow>

              <TextHeader
                color={"black"}
                marginLeft={"3vh"}
                size={"3.5vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"1vh"}
                isPhone={props.isPhone}

              >
                LJUDFILER VARJE MÅNDAD
            </TextHeader>
              <TextHeader
                color={"rgba(25,51,89,1)"}
                marginLeft={"3vh"}
                size={"2vh"}
                fontFamily={"Avenir"}
                FontWeight={"heavy"}
                padding={"25"}
                isPhone={props.isPhone}

              >
                Nya program och meditioner varje <br /> mánad
            </TextHeader>
            </TableRow>
          </TableColumn>
        </div>
      </div>
    </ColorBlock>
  </div>
);
