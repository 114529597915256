import React, { Component } from "react";

interface IntHeader {
    isPhone?: boolean
  }

export const Header = (props: IntHeader) => (

    <div style={{ position: "fixed",flexDirection:'row', justifyContent: 'flex-end', width: '100%', display: 'flex', zIndex: 999, alignItems:'center', height:'70px'  }}>
        <div onClick={()=>window.open('https://player.mindbooztapp.com','_self')} style={{width: props.isPhone ?'70px' : '110px',border:"1px rgba(255,255,255,0) solid", cursor: "pointer", height:'30px',flexDirection:'row',justifyContent: 'center',alignItems:'center', display: 'flex',marginRight:'25px', borderRadius:'3px', backgroundColor:'rgba(146,146,146,0.5)', boxShadow:'-2px 10px 33px 3px rgba(0,0,0,0.5)'}}>
            <h1 style={{ textAlign:'center', color: 'rgba(255,255,255,1)', fontSize: props.isPhone ? 8 : 15 }}>Log in/Sign Up</h1>
        </div>
    </div>
);
