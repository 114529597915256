import React, { Component } from "react";
import {
  ColorBlock,
  TextBlock,
  TableColumn,
  TableRow
} from "../components/blocks.component";
import lightBulb from "../assets/lightBulb.png";
import { TextHeader } from "../components/text.component";

interface Goals {
  isPhone: boolean;
}

export const GoalsScreen = (props: Goals) => (
  <ColorBlock
    color={"rgba(19,36,60,1)"}
    vhHeight={!props.isPhone ? "50vh" : "100vh"}
    style={{ flex: 1, flexDirection: "column", display: "flex" }}
  >
    <TextBlock justifyContent={"flex-start"} height={"100%"} isPhone={props.isPhone}>
      <TextHeader
        color={"white"}
        size={"4vh"}
        fontFamily={"Avenir"}
        textAlign={"center"}
        isPhone={props.isPhone}

      >
        MED MINDBOOZT KAN DU
      </TextHeader>
      
      <div style={{  flexDirection: !props.isPhone ?  "row": "column", display: "flex" , justifyContent: 'center'}}>
      <div style={{ flex: 0.30}} >
        <TableColumn justifyContent={"flex-end"} isPhone={props.isPhone}>
          <TableRow  >
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              <img
                src={lightBulb}
                style={{ height: "10vh", justifyContent: "center" }}
              />
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              SOMN
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              Vakna utvilad och redo for <br /> nästa dag
            </TextHeader>
          </TableRow>
        </TableColumn>
        </div>
        <div style={{ flex: 0.30}}>
        <TableColumn justifyContent={"flex-end"} isPhone={props.isPhone}> 
          <TableRow >
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              <img
                src={lightBulb}
                style={{ height: "10vh", justifyContent: "center" }}
              />
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              FOKUS
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
             Bill mer fokuserad ochslápp<br/>oro och rädslor
            </TextHeader>
          </TableRow>
        </TableColumn>
        </div>
        <div style={{ flex: 0.30}}>
        <TableColumn justifyContent={"flex-end"} isPhone={props.isPhone}>
          <TableRow >
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              <img
                src={lightBulb}
                style={{ height: "10vh", justifyContent: "center" }}
              />
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              MEDITERA
            </TextHeader>
            <TextHeader
              color={"white"}
              size={"2vh"}
              fontFamily={"Avenir"}
              FontWeight={"heavy"}
              textAlign={"center"}
              isPhone={props.isPhone}
            >
              Iära dig Bli mer avslappnad<br/>och lugn
            </TextHeader>
          </TableRow>
        </TableColumn>
        </div>
      </div>
    </TextBlock>
  </ColorBlock>
);
