import React, { Component } from "react";
import {
  ImageBlock,
  TextBlock,
} from "../components/blocks.component";
import image1 from "../assets/image-1.jpg";
import { TextHeader } from "../components/text.component";
import { Parallax, Background } from 'react-parallax';

interface HomeScreen {
  isPhone?: boolean;
}
export const HomeScreen = (props: HomeScreen) => {

  return (
    < Parallax
      blur={{ min: -15, max: 15 }}
      bgImage={image1}
      bgImageAlt="the dog"
      strength={!props.isPhone ? - 150: 100}
      style={{ height: !props.isPhone ? '100vh': '80vh', display: 'flex', justifyContent: props.isPhone ? 'center' : 'flex-start' }}
    >
{/* rgba(110,198,238, 1) */}
      <TextBlock justifyContent={"flex-end"} height={"50%"} isPhone={!props.isPhone}>
        <TextHeader color={"rgba(110,198,208, 1)"} marginLeft={props.isPhone ? "0": "3vw"} size={!props.isPhone ? "4vw": "8vw"} style={{textAlign: props.isPhone ? 'center': 'start'}}>
          MER FOKUS <br /> LUGN OCH <br /> BÄTTRE SÖMN
      </TextHeader>
      </TextBlock>
      <TextBlock justifyContent={"flex-start"} height={"50%"}>
        <TextHeader
          color={"rgba(30,30,30,0.6)"}
          marginLeft={"3vw"}
          size={!props.isPhone ? "2vw": "4vw"}
          fontFamily={"Avenir"}
          style={{textAlign: props.isPhone ? 'center': 'start'}}
        >
          BÖRJA REDAN IDAG
      </TextHeader>
      </TextBlock> <div style={{ height: '200px' }} />
    </Parallax >
  )
};
