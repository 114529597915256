import styled, { css } from "styled-components";
import { string } from "prop-types";

interface imageBlockProps {
  imageSource: string;
  imageHeight: string;
}

export const ImageBlock = styled.div<imageBlockProps>`
  background-image: url(${props => props.imageSource});
  height: 500vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  `;

interface ColorBlocks {
  color: string;
  vhHeight?: string;
}
export const ColorBlock = styled.div<ColorBlocks>`
  background-color: ${props => props.color};
  height: ${props => props.vhHeight || "50vh"};
`;
interface TextBlocks {
  justifyContent?: string;
  height?: string;
  paddingLeft?: string;
  backgroundColor? : string;
  isPhone?: boolean;
  

}
export const TextBlock = styled.div<TextBlocks>`
  height: ${props => props.height};
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  display: flex;
  padding-left: ${props => props.isPhone ? props.paddingLeft || '0px' : null};
  background-color:${props => props.backgroundColor};

`;

interface table {
  justifyContent?: string;
  backgroundColor? : string;
  paddingLeft?: string;
  isPhone? : boolean;
}

export const TableColumn = styled.div<table>`
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  display: flex;
  padding-left: ${props => !props.isPhone ? props.paddingLeft || '0px' : '0px'};
  background-color:${props => props.backgroundColor};
  padding-top: 0
`;

interface table {
  justifyContent?: string;
  backgroundColor? : string;

}

export const TableRow = styled.div<table>`
  height:50%;
  width: 100%;
  justify-content: ${props => !props.isPhone ? props.justifyContent : 'center'};
  flex-direction: row;
  background-color:${props => props.backgroundColor};

`;