import React, { Component } from "react";
import "./App.css";
import Homepage from "./routes/home.route";
import {Header} from "./components/header.component"
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Main } from "./routes/main.route";
import {Privacy } from  "./routes/privacy.route"
interface AppStates {
  pageWidth: number;
  isPhone: boolean;
}

class App extends Component<any, AppStates> {
  

  
  render() {

    return <Router> 
      <Route path="/" exact component={Main} />
      <Route path="/privacy" exact component={Privacy} />

    </Router> 
  }
}

export default App;
