import React, { useState, useEffect } from 'react'
import { Header } from '../components/header.component';
import Homepage from './home.route';

export const Main = () => {

    
    const [isPhone, setIsPhone]= useState(false)
    const [pageWidth, setPageWidth] = useState(900)
    useEffect(() => {
        if (window.innerWidth < 600) {
            setIsPhone(true)
        }
        const resizeEvent = (e: any) => {
            if (e.target.innerWidth !== pageWidth) {
                setPageWidth(e.target.innerWidth)
              if (e.target.innerWidth < 700) {
                setIsPhone(true)
              }
              else {
                setIsPhone(false)
              }
            }
          }
        window.addEventListener('resize', resizeEvent)

        return () => {
            window.removeEventListener('resize', resizeEvent)

        }
    }, [])
    return ( <React.Fragment>
        <Header isPhone={isPhone}/>
        <Homepage  isPhone={isPhone} />
        </React.Fragment>
             ) 
}

// currentWidth={this.state.pageWidth}