import React, { Component } from "react";
import {
  ImageBlock,
  ColorBlock,
  TextBlock
} from "../components/blocks.component";
import image2 from "../assets/image-2.jpg";
import image3 from "../assets/image-3.jpg";
import image4 from "../assets/image-4.jpg";
import { HomeScreen } from "./homeScreen.route";
import { Description } from "./mindBooztDesc.route";
import { GoalsScreen } from "./goals.route.";
import { TextHeader } from "../components/text.component";
import { Parallax } from "react-parallax";
import {Link} from "react-router-dom";

interface Home {
  currentWidth?: number ;
  isPhone?: boolean
}

class Homepage extends Component<any, Home> {
  
 

  render() {
    
    return (
      <div>
        <HomeScreen isPhone={this.props.isPhone}/>

        <Description isPhone={this.props.isPhone}/>
        <GoalsScreen isPhone={this.props.isPhone}/>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={image2}
          bgImageAlt="the dog"
          strength={-70}
          style={{ height: "80vh", display: "flex" }}
        >
          <TextBlock justifyContent={"flex-end"} height={"50%"}>
            <TextHeader color={"black"} marginLeft={"3vh"} size={this.props.isPhone ? "4vh": "6vh"}>
              DET LÄTTA SÄTTET
              <br />
              ATT MEDITERA
            </TextHeader>
          </TextBlock>
          <TextBlock justifyContent={"flex-start"} height={"50%"}>
            <TextHeader
              color={"white"}
              marginLeft={"3vh"}
              size={this.props.isPhone ? "2.4vh": "3vh"}
              fontFamily={"Avenir"}
            >
              Mindboozt är det självklara
              <br />
              valet för dig som vill sova
              <br />
              bättre, bli mer fokuserad och
              <br />
              skapa mer balans i livet. Våra
              <br />
              program är ny och vi har även
              <br />
              program för dig som är van
            </TextHeader>
          </TextBlock>
          <div style={{ height: "200px" }} />
        </Parallax>
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={image3}
          bgImageAlt="the dog"
          strength={-100}
          style={{ height: "80vh", display: "flex", justifyContent:'flex-end'  }}
        >
          <TextBlock justifyContent={"flex-end"} height={"50%"}>
            <TextHeader
              color={"black"}
              size={this.props.isPhone ? "4vh": "6vh"}
              textAlign={"end"}
              style={{ flexDirection: "row" }}
              marginRight={"3vh"}
            >
              AVSLAPPNING
            </TextHeader>
          </TextBlock>
          <TextBlock justifyContent={"flex-start"} height={"50%"}>
            <TextHeader
              color={"white"}
              size={this.props.isPhone ? "2.4vh": "3vh"}
              fontFamily={"Avenir"}
              textAlign={"end"}
              marginRight={"3vh"}
            >
              ta ett djupt andetag och slappna av....
              <br />
              Släpp alla spänninga och andas ut...
            </TextHeader>
          </TextBlock>
          <div style={{ height: "200px" }} />
        </Parallax>

        <ColorBlock color={"rgba(0,0,0,1)"} vhHeight={"20vh"}>
          <TextBlock justifyContent={"center"} height={"100%"}>
            <TextHeader
              color={"rgba(138,202,234,1)"}
              size={this.props.isPhone ? "1.9vh": "2vh"}
              textAlign={"start"}
              style={{ flexDirection: "column" }}
              marginRight={"3vh"}
              paddingLeft={"30px"}
            >
              "ALMOST EVERYTHING WILL WORK AGAIN IF YOU UNPLUG
              <br />
              IT FOR A FEW MINUTES, INCLUDING YOU."
              <br />
              -ANNE LAMOTT
            </TextHeader>
          </TextBlock>
        </ColorBlock>
        
        <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={image4}
          bgImageAlt="the dog"
          strength={-100}
          style={{ height: "80vh", display: "flex" }}
        >
          <TextBlock justifyContent={"flex-end"} height={this.props.isPhone ? "30%": "40%" }>
            <TextHeader
              color={"rgba(138,202,234,1)"}
              size={this.props.isPhone ? "4vh": "6vh"}
              textAlign={"start"}
              style={{ flexDirection: "row" }}
              marginRight={"3vh"}
              paddingLeft={"30px"}
            >
              SOV GOTT
              <br />- HELA NATTEN
            </TextHeader>
          </TextBlock>
          <TextBlock justifyContent={"flex-start"} height={this.props.isPhone ? "70%": "60%" }>
            <TextHeader
              color={"white"}
              size={this.props.isPhone ? "2.5vh": "3vh"}
              fontFamily={"Avenir"}
              textAlign={"start"}
              marginRight={"3vh"}
              paddingLeft={"30px"}

            >
              Dessa meditationen kommer hjälpá dig att slappna
              <br />
              av och sova. Att i perioder ha svårt att sova råkar
              <br />
              de allra flesta ut för någon gång och det kan bero
              <br />
              på flera olika anledningar. Den här meditationen
              <br />
              har fokus i att slappna av och att stilla dinna
              <br />
              tankar så du kan somna och sova hela natten.
            </TextHeader>
          </TextBlock>
          <div style={{ height: "200px" }} />
        </Parallax>

        <ColorBlock color={"rgba(212,211,212,1)"} vhHeight={"50vh"}>
          <TextBlock
            justifyContent={"flex-end"}
            style={{ flexDirection: "column" }}
            height={"50%"}
          >
            <TextHeader
              color={"rgba(140,204,236,1)"}
              size={this.props.isPhone ? "4vh": "6vh"}
              textAlign={"end"}
              style={{ flexDirection: "row" }}
              marginRight={"3vh"}
            >
              FOCUS OCH
              <br />
              PRESTATION
            </TextHeader>
          </TextBlock>
          <TextBlock justifyContent={"flex-start"} height={"50%"}>
            <TextHeader
              color={"black"}
              size={this.props.isPhone ? "2.5vh": "4vh"}
              fontFamily={"Avenir"}
              textAlign={"end"}
              marginRight={"3vh"}
            >
              Guidade inspelningar fōr att õka
              <br />
              fokus, sjālvkãnsla och släppa oro
              <br />
              och rädslor
            </TextHeader>
          </TextBlock>
        </ColorBlock>
        <ColorBlock color={"rgba(86,107,136,1)"} vhHeight={"50vh"}>
          <TextBlock justifyContent={"flex-start"} height={"100%"} paddingLeft={'0px'}>
            <TextHeader
              color={"black"}
              size={this.props.isPhone ? "3vh": "5vh"}
              fontFamily={"Avenir"}
              textAlign={"center"}
            >
              VAD SÄGER DE SOM
              <br />
              ANVÄNDER MINDBOOZT
            </TextHeader>
          </TextBlock>
        </ColorBlock>
        {/* <ColorBlock color={"rgba(255,255,255,1)"} vhHeight={"30vh"} /> */}
        <ColorBlock color={"rgba(0,0,0,1)"} vhHeight={"10vh"} style={{justifyContent:'center', display: "flex" }}>
        <h5  style={{color:'white', cursor: "pointer"}}>
        <Link to="/privacy" style={{color:'white'}}>Privacy Policy</Link>
        </h5>
        </ColorBlock>

      </div>
    )
  }
}

export default Homepage;
