import styled, { css } from "styled-components";

interface textHeader1 {
  color?: string;
  marginLeft?: string;
  size?: string;
  fontFamily?: string;
  FontWeight?: string;
  padding?: string;
  backgroundColor?:string;
  textAlign?:string;
  marginRight?:string;
  isPhone?: boolean;
  paddingLeft?: string;
}

export const TextHeader = styled.h1<textHeader1>`
  color: ${props => props.color};
  margin-left: ${props => !props.isPhone ? props.marginLeft : "0"};
  margin-right:${props => !props.isPhone ? props.marginRight: "0"};
  font-size: ${props => props.size};
  font-family: ${props => props.fontFamily || null};
  FontWeight: ${props => props.FontWeight || null};
  padding-top: ${props => props.padding || null};
  background-color:${props => props.backgroundColor};
  text-align: ${props => !props.isPhone ? props.textAlign : 'center'};
  padding-left ${props => !props.isPhone ? props.paddingLeft : 'center'};
`;

export const P =styled.p `
color: rgba(53,53,53,1);
`
